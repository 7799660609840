import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Meta from '../components/Meta'
import Layout from '../components/Layout'
import Masonry from '../components/Masonry'

function Page({ location }) {
  const { shareImage } = useStaticQuery(graphql`
    query {
      shareImage: file(
        relativePath: { eq: "services/clearance-hedges-feature-5.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <>
      <Meta
        title="One-Off Clearances & Hedge Cutting"
        description="We offer one-off clearances and hedge cutting across Berkshire and Surrey and also offer waste disposal. We make light work of big jobs."
        location={location}
        image={shareImage}
      />
      <Layout>
        <section className="bg-white overflow-hidden">
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray">
                One-Off Clearances &amp; Hedge Cutting
              </h3>
            </div>
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mt-8 lg:mt-0">
                <div className="text-base max-w-prose mx-auto lg:max-w-none">
                  <p className="text-lg text-gray-light">
                    We offer one-off clearances and hedge cutting across
                    Berkshire and Surrey and also offer waste disposal. Our
                    machinery is state of the art and industry rated which means
                    we make light work of big jobs. We are fully licensed to use
                    chainsaws for tough branches and clearances, and use
                    stand-alone tripod ladders which allow us to reach heights
                    in excess of 20 foot. We carry an Upper Tier Waste Carriers
                    License which allows us to dispose of green waste if needed.
                  </p>
                </div>
                <div className="mt-5 prose prose-indigo text-gray-light mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                  <p>
                    For work that involves trees, we have links with renowned
                    tree surgeons for large tree works (felling and
                    maintaining).
                  </p>
                  <p>
                    Please be aware that nesting season (Birds) is from February
                    to the end of July, so it is best to conduct heavy works
                    where active nests are likely, out of these months.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="max-w-7xl mx-auto mb-16">
          <Masonry minWidth={350}>
            <StaticImage
              src="../images/services/clearance-hedges-feature-1.jpg"
              alt="Featured work"
              aspectRatio={4 / 3}
              transformOptions={{ fit: 'inside' }}
              placeholder="blurred"
            />
            <StaticImage
              src="../images/services/clearance-hedges-feature-2.jpg"
              alt="Featured work"
              aspectRatio={16 / 9}
              transformOptions={{ fit: 'inside' }}
              placeholder="blurred"
            />
            <StaticImage
              src="../images/services/clearance-hedges-feature-3.jpg"
              alt="Featured work"
              aspectRatio={16 / 9}
              transformOptions={{ fit: 'inside' }}
              placeholder="blurred"
            />
            <StaticImage
              src="../images/services/clearance-hedges-feature-4.jpg"
              alt="Featured work"
              aspectRatio={3 / 5}
              transformOptions={{ fit: 'inside' }}
              placeholder="blurred"
            />
            <StaticImage
              src="../images/services/clearance-hedges-feature-5.jpg"
              alt="Featured work"
              aspectRatio={4 / 3}
              transformOptions={{ fit: 'inside' }}
              placeholder="blurred"
            />
          </Masonry>
        </section>
      </Layout>
    </>
  )
}

Page.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Page
